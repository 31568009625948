import React from "react"
import styled from "styled-components"
import GallerySlider from "../components/GallerySlider"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "../components/Button"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/thumbs"

const PhotoGallery = () => {
  const data = useStaticQuery(graphql`
    query PhotoGalleryQuery {
      allDatoCmsInfinitegallery(limit: 12) {
        edges {
          node {
            id
            infinitegalleryphoto {
              alt
              createdAt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const PhotosArray = data.allDatoCmsInfinitegallery.edges

  return (
    <PhotoGalleryWrapper>
      <SliderWrapper>
        <GallerySlider photos={PhotosArray} />
      </SliderWrapper>
      <Button big="true" primary="true" to="/gallery">
        Zajrzyj do naszej galerii
      </Button>
    </PhotoGalleryWrapper>
  )
}

export default PhotoGallery

const PhotoGalleryWrapper = styled.div`
  margin-bottom: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 992px) {
  }
`

const SliderWrapper = styled.div`
  position: relative;
  width: 60vw;
  height: 80vh;
  box-shadow: 2px 2px 4px rgba(230, 120, 100, 0.4);
  margin-bottom: 4vh;
  border-radius: 12px;
  @media screen and (max-width: 992px) {
    width: 95vw;
    height: 120vw;
  }
  .swiper {
    position: relative;
    height: 74%;

    &.photo-image-slider-thumbs {
      box-sizing: border-box;
      height: 24%;
      margin: 10px;

      .swiper-slide {
        &-thumb-active img {
          border: 2px solid #f6b597;
        }
      }
    }
  }
`
