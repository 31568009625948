import React from "react"
import styled from "styled-components"

const Triangle = props => {
  const { top, colors, reverse, className } = props
  return (
    <>
      <TriangleDivWrapper className={className}>
        <TriangleDiv
          reverse={reverse}
          top={top}
          firstColor={colors.firstColor}
          secondColor={colors.secondColor}
        ></TriangleDiv>
      </TriangleDivWrapper>
    </>
  )
}

export default Triangle

const TriangleDivWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 2;
  &.about__triangle {
    position: absolute;
    bottom: 0;
  }
  @media screen and (max-width: 868px) {
    height: 10vh;
  }
`

const TriangleDiv = styled.div`
  ${({ reverse, firstColor }) =>
    reverse
      ? `border-right: 100vw solid ${firstColor};`
      : `border-left: 100vw solid ${firstColor};`};
  ${({ top, secondColor }) =>
    top
      ? `border-top: 10vh solid ${secondColor};`
      : `border-bottom: 10vh solid ${secondColor};`};

  @media screen and (max-width: 868px) {
    ${({ reverse, firstColor }) =>
      reverse
        ? `border-right: 100vw solid ${firstColor};`
        : `border-left: 100vw solid ${firstColor};`};
    ${({ top, secondColor }) =>
      top
        ? `border-top: 10vh solid ${secondColor};`
        : `border-bottom: calc(10vh + 1px) solid ${secondColor};`};
  }
`
