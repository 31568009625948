import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ExternalLinkButton } from "./ExternalLinkButton"
import VideoBg from "../assets/videos/bg-video.mp4"
import { GiPlainArrow } from "react-icons/gi"
import { Link } from "gatsby"

const Hero = () => {
  const [isArrowVisible, setArrowVisibility] = useState(true)

  const handleScroll = () =>
    document.body.getBoundingClientRect().top
      ? setArrowVisibility(false)
      : setArrowVisibility(true)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <HeroContainer>
      <HeroBg>
        <Video src={VideoBg} type="video/mp4" autoPlay loop muted playsInline />
      </HeroBg>
      <HeroContent>
        <HeroItems>
          <HeroH1>Glammy Beauty&amp;Mani</HeroH1>
          <HeroP>
            Profesjonalne usługi kosmetologiczne.
          </HeroP>
          <ExternalLinkButton primary="true" big="true" round="true" value="" target="_blank" href="https://booksy.com/pl-pl/20099_glammy-beauty-mani_paznokcie_8820_krakow">
            Umów się na wizytę
          </ExternalLinkButton>
        </HeroItems>
      </HeroContent>
      {isArrowVisible ? (
        <AnimatedArrow to="#about">
          <GiPlainArrow className="arrow-icon" />
        </AnimatedArrow>
      ) : null}
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 1rem;
  position: relative;
  margin-top: -80px;
  height: 100vh;
  box-shadow: 3px 3px 10px rgba(255, 190, 180, 0.65);

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(255, 190, 180, 0.3) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  }
`

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1500px) / 2);
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #fff;
  line-height: 1.1;
  font-weight: bold;
`

const HeroH1 = styled.h1`
  font-size: clamp(2rem, 8vw, 7rem);
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  padding: 0 1rem;
  font-family: "Allura", cursive;
  font-weight: 400;
  text-align: center;
`

const HeroP = styled.p`
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
  font-weight: 400;
  text-align: center;
  font-family: "Allura", cursive;
`

const AnimatedArrow = styled(Link)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 50px;
  height: 70px;
  z-index: 3;
  bottom: 0;
  cursor: pointer;
  .arrow-icon {
    position: absolute;
    font-size: 3rem;
    color: #ddd;
    animation: arrow 1.5s infinite;
  }

  @keyframes arrow {
    from {
      top: -5px;
    }
    to {
      top: 15px;
    }
  }
`
