import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import About from "../sections/About"
import Services from "../sections/Services"
import News from "../sections/News"
import PhotoGallery from "../sections/PhotoGallery"
import ContactSection from "../sections/ContactSection"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <About />
    <Services />
    <News />
    <PhotoGallery />
    <ContactSection />
  </Layout>
)

export default IndexPage
