import React from "react"
import styled from "styled-components"
import { IndexHeader } from "../components/IndexHeader"
import SectionText from "../components/SectionText"
import { useStaticQuery, graphql } from "gatsby"
import SocialBar from "../components/SocialBar"

const ContactSection = () => {
  const contactInfoData = useStaticQuery(graphql`
    query contactInfoQuery {
      allDatoCmsContactinfo {
        edges {
          node {
            email
            telefon
            adres
          }
        }
      }
    }
  `)

  const datoData = contactInfoData.allDatoCmsContactinfo.edges[0].node

  return (
    <ContactSecitonWrapper>
      <ContactSectionInfo>
        <IndexHeader primary={true} long={true}>
          Kontakt
        </IndexHeader>
        <ContactSectionText>
          <SectionText>{datoData.adres}</SectionText>
          <SectionText>{datoData.telefon}</SectionText>
          <SectionText>{datoData.email}</SectionText>
        </ContactSectionText>
        <ContactSectionSocial>
          <SocialBar contactClass="socialbar-contact" />
        </ContactSectionSocial>
      </ContactSectionInfo>
      <iframe
        className="google-map-contact"
        title="Glammy Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1120382821628!2d19.903791415718118!3d50.08418917942647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b4a6d40c7ab%3A0xcc4cfecf82ac1a56!2sGlammy%20Beauty%26Mani!5e0!3m2!1spl!2spl!4v1650640020604!5m2!1spl!2spl"
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </ContactSecitonWrapper>
  )
}

export default ContactSection

const ContactSecitonWrapper = styled.div`
  position: relative;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
  .google-map-contact {
    border-radius: 12px;
    width: 50vw;
    height: 60vh;
    @media screen and (max-width: 992px) {
      margin: 15px;
      width: 90vw;
    }
    @media screen and (max-width: 768px) {
      width: 96vw;
    }
  }
`

const ContactSectionInfo = styled.div`
  min-height: 50vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: 992px) {
    min-height: 60vh;
    width: 90vw;
  }
`

const ContactSectionText = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
  align-items: center;
  font-size: 1.5rem;
`

const ContactSectionSocial = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
