import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Navigation, Thumbs } from "swiper"
import styled from "styled-components"

const GallerySlider = props => {
  const [activeThumb, setActiveThumb] = useState()
  const { photos } = props

  const displayPhotos = isThumb => {
    return photos.map(photo => {
      const image = getImage(photo.node.infinitegalleryphoto)
      const alt = photo.node.infinitegalleryphoto.alt
      const id = photo.node.id

      return (
        <SwiperSlide key={id}>
          <SliderPhoto
            image={image}
            alt={alt}
            objectFit={isThumb ? "fill" : "contain"}
          />
        </SwiperSlide>
      )
    })
  }

  return (
    <>
      <Swiper
        loop={true}
        navigation={true}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className="photo-image-slider"
      >
        {displayPhotos(false)}
      </Swiper>
      <Swiper
        onSwiper={setActiveThumb}
        spaceBetween={5}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className="photo-image-slider-thumbs"
      >
        {displayPhotos(true)}
      </Swiper>
    </>
  )
}

export default GallerySlider

const SliderPhoto = styled(GatsbyImage)`
  margin: 10px 0;
  position: relative;
  height: 100%;
  width: 100%;
`
