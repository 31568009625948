import React from "react"
import styled from "styled-components"
import { Button } from "../components/Button"
import PostInfo from "../components/PostInfo"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const News = () => {
  const newsData = useStaticQuery(graphql`
    query NewsQuery {
      allDatoCmsArticle {
        edges {
          node {
            slug
            title
            creationDate
            id
            articleContentNode {
              childMarkdownRemark {
                excerpt
              }
              id
            }
            articleImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const newArticles = newsData.allDatoCmsArticle.edges
    .sort((a, b) => {
      if (a.node.creationDate < b.node.creationDate) return 1
      if (a.node.creationDate > b.node.creationDate) return -1
      return 0
    })
    .slice(0, 3)

  const newArticlesMapped = newArticles.map(article => {
    const image = getImage(article.node.articleImage)
    const alt = article.node.articleImage.alt
    const id = article.node.id
    const title = article.node.title
    const slug = article.node.slug
    const content = article.node.articleContentNode.childMarkdownRemark.excerpt
    return (
      <PostWrapper key={id}>
        <PostPicture image={image} alt={alt} />
        <PostInfo title={title} slug={slug} content={content}></PostInfo>
      </PostWrapper>
    )
  })

  return (
    <NewsWrapper
      style={newArticles.length ? { display: "grid" } : { display: "none" }}
    >
      {newArticles.length && newArticlesMapped}
      <InfoSection>
        <InfoSectionText>
          Chcesz dowiedzieć się jeszcze więcej o tym czym się zajmujemy?
        </InfoSectionText>
        <InfoSectionText>Sprawdź naszego bloga: </InfoSectionText>

        <Button primary="true" big="true" to="/blog">
          Zobacz artykuły
        </Button>
      </InfoSection>
    </NewsWrapper>
  )
}

export default News

const NewsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  min-height: 100vh;
  gap: 5vw;
  row-gap: 20vh;
  width: 100%;
  background-color: #fff;
  margin-bottom: 25vh;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    margin-bottom: 5vh;
    margin-top: 5vh;
  }
  @media screen and (min-width: 1200px) {
    & div:nth-child(2n) {
      top: 25vh;
    }
  }
`

const PostWrapper = styled.div`
  width: 35vw;
  height: 40vh;
  position: relative;
  place-self: center;

  @media screen and (max-width: 1200px) {
    width: 70vw;
    height: 60vh;
  }
  @media screen and (max-width: 992px) {
    width: 85vw;
    min-height: 70vh;
  }
`
const PostPicture = styled(GatsbyImage)`
  height: 100%;
  border-radius: 12px;
  box-shadow: 1px 1px 12px rgba(230, 120, 100, 0.4);
`

const InfoSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  gap: 10px;
`

const InfoSectionText = styled.p`
  font-family: "Gentium Book Basic";
  font-style: italic;
  text-align: center;
  color: #555;
  text-shadow: 1px 1px 2px #aaa;
  font-size: clamp(1rem, 5vw, 2rem);
  margin-bottom: 10px;
`
