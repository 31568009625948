import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const PostInfo = ({ slug, content, title }) => {
  return (
    <PostInfoWrapper>
      <PostTitle>
        {title[0].toUpperCase() + title.substring(1, title.length)}
      </PostTitle>
      <PostContent>{content}</PostContent>
      <span>
        <ReadMore to={"/blog/" + slug}>Czytaj dalej...</ReadMore>
      </span>
    </PostInfoWrapper>
  )
}

export default PostInfo

const PostInfoWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 15px;
  bottom: -15vh;
  right: -4vw;
  width: 35vw;
  height: 30vh;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(230, 120, 100, 0.5);
  background-color: white;
  z-index: 2;
  font-family: "Gentium Book Basic";

  @media screen and (max-width: 1200px) {
    width: 50vw;
    height: 35vh;
  }
  @media screen and (max-width: 992px) {
    right: -2vw;
    width: 80vw;
    height: 50vh;
  }
`

const PostTitle = styled.h3`
  margin: 5px 0;
  font-size: 1.5rem;
  font-weight: 700;
  color: #555;
  text-shadow: 1px 1px 1px #ccc;
`
const PostContent = styled.p`
  overflow: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-height: 30%;
  font-size: 1.2rem;
  max-height: 70%;
  font-family: "Gentium Book Basic";
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
`
const ReadMore = styled(Link)`
  display: inline;
  text-align: start;
  color: #efd3cf;
  font-family: "Gentium Book Basic";
  font-style: italic;
  font-size: 1.2rem;
  text-shadow: 1px 1px 1px rgba(150, 85, 85, 0.6);
  text-decoration: none;
`
