import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React, { useRef, useEffect } from "react"
import styled from "styled-components"

const TreatmentButton = ({ title, picture, setImage }) => {
  const buttonRef = useRef(null)

  const handleHover = () => {
    setImage(getImage(picture))
  }

  useEffect(() => {
    const button = buttonRef.current
    button.addEventListener("mouseover", handleHover)

    return () => {
      button.removeEventListener("mouseover", handleHover)
    }
  })

  return (
    <TreatmentButtonWrapper
      ref={buttonRef}
      to="/pricing"
      state={{ title: title }}
    >
      <TreatmentButtonIcon />
      <TreatmentButtonTitle>{title}</TreatmentButtonTitle>
    </TreatmentButtonWrapper>
  )
}

export default TreatmentButton

const TreatmentButtonWrapper = styled(Link)`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
  gap: 15px;
  height: 20px;
  width: 100%;
  text-decoration: none;
  color: white;
  align-self: center;
  text-align: left;

  &:hover > div {
    box-shadow: 2px 2px 5px rgba(230, 120, 100, 0.4);
  }
  &:hover > p {
    text-shadow: 1px 1px 2px rgba(230, 120, 100, 0.4);
  }
`

const TreatmentButtonIcon = styled.div`
  min-width: 50px;
  height: 50px;
  background-color: #efd3cf;
  border: 1px solid #fff;
  border-radius: 50%;
  transition: 0.3s all;
`

const TreatmentButtonTitle = styled.p`
  color: #444;
  font-size: 1.2rem;
  transition: 0.3s all;
  font-family: "Gentium Book Basic";
  font-style: italic;
`
