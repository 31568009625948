import styled from "styled-components"
import {Link} from "gatsby"


export const Button = styled(Link)`
  background-color: ${({ primary }) => (primary ? "#efd3cf" : "c66")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "16px 40px" : "10px 32px")};
  color: #fff;
  font-size: ${({ big }) => (big ? "20px" : "16px")};
  outline: none;
  border: none;
  min-width: 100px;
  max-width: 90vw;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: ${({ round }) => (round ? "50px" : "3px")};
  font-family: "Gentium Book Basic";
  font-style: italic;
  font-weight: 700;
  font-size: 1.2rem;
  text-shadow: 1px 1px 1px #955;
  box-shadow: 2px 1px 5px rgba(230, 120, 100, 0.4);

  &:hover {
    background-color: ${({ primary }) => (primary ? "#faebe6" : "#c66")};
    transform: scale(1.1);
    color: #955;
    text-shadow: 1px 1px 2px #efd3cf;
  }
`
