import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "../components/Button"
import { IndexHeader } from "../components/IndexHeader"
import SectionText from "../components/SectionText"
import TreatmentButton from "../components/TreatmentButton"
import { graphql, useStaticQuery } from "gatsby"

const Services = () => {
  const data = useStaticQuery(graphql`
    query servicePictureQuery {
      allDatoCmsServiceCategory(sort: { fields: [position], order: ASC }) {
        edges {
          node {
            categoryName
            categoryImage {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
            id
          }
        }
      }
      allDatoCmsServicePicture {
        nodes {
          pictures {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const image = getImage(
    data.allDatoCmsServiceCategory.edges[0].node.categoryImage
  )

  const backgroundImage1 = data.allDatoCmsServicePicture.nodes[0].pictures[0]
  const backgroundImage2 = data.allDatoCmsServicePicture.nodes[0].pictures[1]
  const categoryData = data.allDatoCmsServiceCategory.edges

  const [displayImage, setDisplayImage] = useState(image)

  const treatments = categoryData.map(item => {
    const picture = item.node.categoryImage
    const title = item.node.categoryName
    const id = item.node.id
    return (
      <TreatmentButton
        key={id}
        title={title}
        setImage={setDisplayImage}
        picture={picture}
      />
    )
  })
  return (
    <>
      <ServicesWrapper>
        <ServicesImagesSection>
          <ServicesImagesWrapper>
            <ServicesImagesRow1>
              <ServicesImage className="image-div-1">
                <GatsbyImage
                  className="main-services-image"
                  image={displayImage}
                  alt="flexible image"
                />
              </ServicesImage>
            </ServicesImagesRow1>
            <ServicesImagesRow2>
              <ServicesImage>
                <GatsbyImage
                  className="image-fix"
                  image={getImage(backgroundImage1)}
                  alt={backgroundImage1.alt}
                />
              </ServicesImage>
              <ServicesImage>
                <GatsbyImage
                  className="image-fix"
                  image={getImage(backgroundImage2)}
                  alt={backgroundImage2.alt}
                />
              </ServicesImage>
            </ServicesImagesRow2>
          </ServicesImagesWrapper>
        </ServicesImagesSection>
        <ServicesInfo>
          <IndexHeader long={true} primary={true}>
            Nasze usługi
          </IndexHeader>
          <ServicesDisplay>{treatments}</ServicesDisplay>
          <SectionText className="services-text">
            Zapraszamy na profesjonalne zabiegi u doświadczonych zawodowców.
          </SectionText>
          <Button primary="true" to="/pricing">
            Sprawdź Pełen Cennik
          </Button>
        </ServicesInfo>
      </ServicesWrapper>
    </>
  )
}

export default Services

const ServicesWrapper = styled.div`
  padding: 3vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ServicesImagesSection = styled.div`
  position: relative;
  min-height: 100vh;
  width: 50vw;
  @media screen and (max-width: 1200px) {
    width: 30vw;
  }
  @media screen and (max-width: 768px) {
    display: none;
    width: 0;
  }
`

const ServicesImagesWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: -25%;
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: 70%;
  transform: rotate(0deg);

  @media screen and (max-width: 1200px) {
    transform: translateX(-65%) rotate(0deg);
  }
`

const ServicesImagesRow1 = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  z-index: 2;
  transform: translateX(10%);

  @media screen and (max-width: 1200px) {
    justify-content: end;
    transform: translateX(20%);
  }
`
const ServicesImagesRow2 = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 5vw;
  transform: rotate(30deg) translateX(-5%);

  @media screen and (max-width: 1200px) {
    width: 130%;
  }
`

const ServicesImage = styled.div`
  position: relative;
  width: 40vh;
  height: 40vh;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(230, 120, 100, 0.4);
  border-radius: 10%;

  &.image-div-1 {
    height: 45vh;
    width: 45vh;
    & img {
      min-width: 45vh;
      min-height: 45vh;
    }
  }

  & .image-fix {
    position: absolute;
    width: 640px;
    transform: rotate(-30deg) translate(-4%, -27%) scale(1.3);
  }
`

const ServicesInfo = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 5vh;
  height: 100%;
  width: 50vw;
  padding: 20px 40px;
  @media screen and (max-width: 1200px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    justify-content: center;
  }
`

const ServicesDisplay = styled.div`
  padding: 20px 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  min-height: 60vh;
  width: 100%;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 768px) {
    padding: 5px;
  }
`
