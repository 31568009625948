import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { IndexHeader } from "../components/IndexHeader"
import SectionText from "../components/SectionText"
import Triangle from "../components/Triangle"
import bgImage from "../assets/images/aboutbgv3.jpg"

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutContentQuery {
      allDatoCmsAboutContent {
        edges {
          node {
            aboutContent
          }
        }
      }
    }
  `)
  const AboutTextContent =
    data.allDatoCmsAboutContent.edges[0].node.aboutContent

  return (
    <AboutContainer>
      <AboutHero />
      <AboutWrapper id="about">
        <AboutTextWrapper>
          <IndexHeader primary={true} long={false}>
            O nas
          </IndexHeader>
          <SectionText>{AboutTextContent}</SectionText>
        </AboutTextWrapper>
        <AboutImage></AboutImage>
      </AboutWrapper>
      <Triangle
        className="about__triangle"
        reverse={true}
        top={true}
        colors={{ firstColor: "#fff", secondColor: "transparent" }}
      />
    </AboutContainer>
  )
}
export default About

const AboutContainer = styled.div`
  position: relative;
  min-height: 80vh;
  width: 100%;
  z-index: -1;
`
const AboutWrapper = styled.div`
  position: relative;
  min-height: 80vh;
  gap: 5vw;
  padding: 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-image: url(${bgImage});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 1078px) {
    height: 100%;
    justify-content: center;
  }
`

const AboutHero = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 1;
`

const AboutImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  @media screen and (max-width: 1078px) {
    display: none;
    width: 0;
  }
`

const AboutTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  gap: 5vh;
  overflow: hidden;
  padding: 25px;
  border-radius: 10px;
  /* box-shadow: 2px 2px 10px rgba(230, 120, 100, 0.4); */
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 3;
  @media screen and (max-width: 1078px) {
    width: 90vw;
  }
`
