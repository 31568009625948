import styled from "styled-components"

export const IndexHeader = styled.h2`
  color: ${({ primary }) => (primary ? "#efd3cf" : "#e3a79e")};
  position: relative;
  font-size: ${({ long }) => (long ? "4.5rem" : "4.5rem")};
  font-family: "Kaushan Script", cursive;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(255, 245, 245, 1);

  @media screen and (max-width: 1078px) {
    font-size: 4.5rem;
  }
`
